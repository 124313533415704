import { defineComponent, ref } from "vue";
import { createDefaultNode } from "@/storage/model/Node";
export default defineComponent({
    name: "GraphNodeEditor",
    setup() {
        const drawer = ref(false);
        let currentNode = ref(createDefaultNode());
        function cancelClick() {
            drawer.value = false;
        }
        function confirmClick() {
            drawer.value = false;
        }
        function showNodeEditor(node) {
            drawer.value = true;
            currentNode.value = node;
        }
        return {
            drawer,
            currentNode,
            cancelClick,
            confirmClick,
            showNodeEditor
        };
    }
});
