import RGGraphMath from "../utils/RGGraphMath";
import { devLog } from "../utils/RGCommon";
import { relationGraphDelegate } from "@/storage/RelationGraphDelegate";
export class SeeksFixedLayouter {
    constructor(layoutOptions, graphOptions) {
        this.layoutOptions = layoutOptions;
        this.graphOptions = graphOptions;
    }
    graphOptions;
    layoutOptions;
    rootNode = undefined;
    allNodes = [];
    __origin_nodes = [];
    doBeforeLayout() {
        const relationGraph = relationGraphDelegate.getRelationGraphView()?.value?.getInstance();
        relationGraph?.setDefaultLineShape(1);
        relationGraph?.setDefaultJunctionPoint("border");
    }
    refresh() {
        this.placeNodes(this.__origin_nodes, this.rootNode);
    }
    placeNodes(allNodes, rootNode) {
        if (!rootNode) {
            devLog("root is null:", rootNode);
            return;
        }
        devLog("layout by root:", rootNode);
        this.__origin_nodes = allNodes;
        this.rootNode = rootNode;
        allNodes.forEach((thisNode) => {
            // thisNode.lot = { eached: false }
            thisNode.lot.eached = false;
            thisNode.lot.notLeafNode = false;
            thisNode.lot.childs = [];
            // thisNode.lot.parent = undefined
            thisNode.lot.index_of_parent = 0;
            thisNode.lot.strength = 0;
            thisNode.lot.placed = false;
        });
        this.allNodes = [];
        const analyticResult = {
            max_deep: 1,
            max_length: 1,
            max_strength: 1
        };
        RGGraphMath.analysisNodes4Didirectional(this.allNodes, [this.rootNode], 0, analyticResult, 0);
    }
}
export default SeeksFixedLayouter;
