<template>
<!--  <div style="float: left;width: 45%">-->
<!--    <UserAdd></UserAdd>-->
<!--  </div>-->

<!--  <div style="float: right;width: 45%">-->
<!--    <RelationAdd></RelationAdd>-->
<!--  </div>-->
</template>
<script>

export default {
  name: "HeaderMenu",
  components: {},

};
</script>
