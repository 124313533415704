import { defineComponent, ref } from "vue";
import { store } from "@/store";
import { createDefaultNode } from "@/storage/model/Node";
import { CircleCloseFilled, CirclePlusFilled, DeleteFilled, Edit } from "@element-plus/icons-vue";
import { relationGraphDelegate } from "@/storage/RelationGraphDelegate";
import GraphNodeEditor from "@/components/GraphNodeEditor.vue";
import ColorPicker from "@/components/ColorPicker.vue";
import DefaultAvatarPicker from "@/components/DefaultAvatarPicker.vue";
export default defineComponent({
    name: "GraphNodeProfile",
    components: { DefaultAvatarPicker, GraphNodeEditor, Edit, CircleCloseFilled, DeleteFilled, CirclePlusFilled, ColorPicker },
    setup() {
        const isShowNodeMenuPanel = ref(false); // 是否展示操作菜单
        let currentNode = ref(createDefaultNode()); // 当前操作的节点
        const nodeMenuPanelPosition = ref({ x: 0, y: 0 }); // 操作菜单位置
        let graphNodeEditor = ref();
        function modifyPanelPosition(x, y) {
            nodeMenuPanelPosition.value.x = x;
            nodeMenuPanelPosition.value.y = y;
        }
        function showNodeProfile(node, x, y) {
            isShowNodeMenuPanel.value = true;
            nodeMenuPanelPosition.value.x = x;
            nodeMenuPanelPosition.value.y = y;
            currentNode.value = node;
        }
        function hideNodeProfile() {
            isShowNodeMenuPanel.value = false;
        }
        function deleteNode() {
            relationGraphDelegate.deleteNode(currentNode.value);
            hideNodeProfile();
        }
        function addLine(from, to) {
            const line = { from: from.id, to: to.id, text: "节点关系描述" };
            relationGraphDelegate.addLine(line); // 添加当前节点到新增子节点的关系表示
            hideNodeProfile();
        }
        function addChildNode() {
            const newNode = createDefaultNode();
            relationGraphDelegate.addGraphNode(newNode);
            addLine(currentNode.value, newNode);
            // showEditorDrawer(newNode);
            return newNode;
        }
        function addParentNode() {
            const newNode = createDefaultNode();
            relationGraphDelegate.addGraphNode(newNode);
            addLine(newNode, currentNode.value);
            return newNode;
        }
        function editNode() {
            showEditorDrawer(currentNode.value);
            return currentNode.value;
        }
        function showEditorDrawer(node) {
            isShowNodeMenuPanel.value = false;
            console.log("showNodeEditor", graphNodeEditor.value);
            graphNodeEditor.value?.showNodeEditor(node);
        }
        return {
            store,
            currentNode,
            nodeMenuPanelPosition,
            isShowNodeMenuPanel,
            graphNodeEditor,
            deleteNode,
            addChildNode,
            addParentNode,
            editNode,
            hideNodeProfile,
            showNodeProfile,
            addLine,
            modifyPanelPosition
        };
    }
});
