import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", null, [
        _createElementVNode("div", {
            class: "c-my-node",
            style: _normalizeStyle({ backgroundImage: `url(${_ctx.node.data.avatar})`, border: '#decd16 solid 1px' })
        }, [
            _createElementVNode("div", {
                class: "c-node-name",
                style: _normalizeStyle({ color: _ctx.node.fontColor })
            }, _toDisplayString(_ctx.node.text), 5)
        ], 4)
    ]));
}
