import { defineComponent, ref } from "vue";
import { store } from "@/store";
import { mapGetters } from "vuex";
import { relationGraphDelegate } from "@/storage/RelationGraphDelegate";
import ColorPicker from "@/components/ColorPicker.vue";
export default defineComponent({
    name: "GraphLineEditor",
    components: { ColorPicker },
    ...mapGetters({
        nodeList: "getNodeList"
    }),
    setup() {
        // const nodeList = computed<Node[]>(() => store.state.graph_json_data.nodes);
        let currentLine = ref({ from: "", to: "", text: "节点关系描述" });
        const isShowLinePanel = ref(false); // 是否展示操作菜单
        const linePanelPosition = ref({ x: 0, y: 0 }); // 操作菜单位置
        function onLinePointChange(id) {
            relationGraphDelegate.refresh();
        }
        function showLineEditor(line, link, x, y) {
            linePanelPosition.value.x = x;
            linePanelPosition.value.y = y;
            isShowLinePanel.value = true;
            currentLine.value = line;
        }
        function hideLineEditor(event) {
            // isShowLinePanel.value = false;
            if (event.target === event.currentTarget) {
                isShowLinePanel.value = false;
            }
        }
        return {
            store,
            currentLine,
            isShowLinePanel,
            linePanelPosition,
            showLineEditor,
            hideLineEditor,
            onLinePointChange
        };
    }
});
