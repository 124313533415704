// @ts-ignore
import { createStore } from "vuex";
import { createDefaultRelationGraphData } from "@/storage/model/RelationGraphData";
// 定义 injection key
export const key = Symbol();
export const store = createStore({
    state: {
        normal_color: ["#FF4500", "#FFA500", "#FFFF00", "#00FF00", "#00BFFF", "#0000FF", "#8B00FF"],
        graph_json_data: createDefaultRelationGraphData(),
    },
    getters: {
        getNodeList(state) {
            return state.graph_json_data.nodes;
        }
    },
    mutations: {
        updateRootId(state, newRootId) {
            state.graph_json_data.rootId = newRootId;
        },
        addNode(state, node) {
            state.graph_json_data.nodes.push(node);
        },
        deleteNode(state, deleteNode) {
            deleteNode.isHide;
            // state.graph_json_data.nodes = state.graph_json_data.nodes.filter(node => {
            //   return node.id != deleteNode.id;
            // });
        },
        addLine(state, line) {
            state.graph_json_data.lines.push(line);
        },
        deleteLine(state, deleteLine) {
            state.graph_json_data.lines = state.graph_json_data.lines.filter(line => {
                return line == deleteLine;
            });
        },
        updateGraphData(state, graph_json_data) {
            state.graph_json_data = graph_json_data;
        }
    },
    actions: {
        asyncUpdateRootId({ commit }, newRootId) {
            commit("updateRootId", newRootId);
        },
        asyncAddNode({ commit }, node) {
            commit("addNode", node);
        },
        asyncDeleteNode({ commit }, node) {
            commit("deleteNode", node);
        },
        asyncDeleteLine({ commit }, line) {
            commit("deleteLine", line);
        },
        asyncAddLine({ commit }, line) {
            commit("addLine", line);
        },
        asyncUpdateGraphData: ({ commit }, data) => {
            commit("updateGraphData", data);
        }
    },
    modules: {}
});
