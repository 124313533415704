import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3300d08f"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "color-picker" };
const _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_el_color_picker = _resolveComponent("el-color-picker");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_color_picker, {
            modelValue: _ctx.modelValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.modelValue) = $event)),
            size: "large"
        }, null, 8, ["modelValue"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colors, (color) => {
            return (_openBlock(), _createElementBlock("div", {
                key: color,
                onClick: ($event) => (_ctx.selectColor(color)),
                style: _normalizeStyle({ backgroundColor: color }),
                class: "color-box"
            }, null, 12, _hoisted_2));
        }), 128))
    ]));
}
