<template>
  <div></div>
</template>

<script>
export default {
  name: "SideMenu",
};
</script>
<style scoped></style>
