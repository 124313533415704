import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1ca67a1a"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "avatar-picker" };
const _hoisted_2 = ["onClick", "src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.avatarGroups, (avatars, index) => {
            return (_openBlock(), _createElementBlock("div", {
                class: "avatar-row",
                key: index
            }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(avatars, (avatar) => {
                    return (_openBlock(), _createElementBlock("img", {
                        alt: "",
                        key: avatar,
                        onClick: ($event) => (_ctx.selectAvatar(avatar)),
                        src: avatar,
                        style: { "width": "35px", "height": "35px", "border-radius": "5px", "margin-right": "10px" }
                    }, null, 8, _hoisted_2));
                }), 128))
            ]));
        }), 128))
    ]));
}
