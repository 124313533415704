import { store } from "@/store";
import { ref } from "vue";
import { createDefaultRelationGraphData } from "@/storage/model/RelationGraphData";
import { ElNotification } from "element-plus";
class RelationGraphDelegate {
    _relationGraphView = ref();
    localStorage = window.localStorage;
    graphDataStorageKey = "GRAPH_DATA_STORAGE_KEY";
    addGraphNode(node) {
        node.id = String(new Date().getTime());
        store.dispatch("asyncAddNode", node).then(() => {
            this._relationGraphView?.value?.getInstance()?.addNodes([node]);
            this._relationGraphView?.value?.getInstance()?.refresh();
            this.focusNodeById(node.id);
        });
        return node;
    }
    addLine(line) {
        // lineDexie.nodes.add(line);
        store.dispatch("asyncAddLine", {
            from: line.from, to: line.to, text: line.text, color: "#43a2f1"
        }).then(() => {
            this._relationGraphView?.value?.getInstance()?.addLines([line]);
            this._relationGraphView?.value?.getInstance()?.refresh();
        });
        return line;
    }
    createNewRelationGraph() {
        let graph_data = createDefaultRelationGraphData();
        this._relationGraphView?.value?.setJsonData(graph_data);
        store.dispatch("asyncUpdateGraphData", graph_data).then(r => {
            this.localStorage.setItem(this.graphDataStorageKey, JSON.stringify(graph_data));
        });
    }
    createNewRelationGraphFromStorage() {
        let graph_data = relationGraphDelegate.getRelationGraphData();
        this._relationGraphView?.value?.setJsonData(graph_data);
        store.dispatch("asyncUpdateGraphData", graph_data).then(r => {
            this.localStorage.setItem(this.graphDataStorageKey, JSON.stringify(graph_data));
        });
    }
    deleteLine(line) {
        store.dispatch("asyncDeleteLine", line).then(() => {
            this._relationGraphView?.value?.getInstance()?.removeLinkById(line.from, line.to);
        });
    }
    deleteNode(node) {
        this._relationGraphView?.value?.getInstance()?.removeNodeById(node.id);
        this._relationGraphView?.value?.getInstance()?.refresh();
        store.dispatch("asyncDeleteNode", node).then(() => {
        });
    }
    focusNodeById(id) {
        this._relationGraphView?.value?.getInstance().focusNodeById(id);
    }
    getRelationGraphData() {
        const data = this.localStorage.getItem(this.graphDataStorageKey);
        if (data == null) {
            return store.state.graph_json_data;
        }
        return JSON.parse(data);
    }
    getRelationGraphView() {
        return this._relationGraphView;
    }
    refresh() {
        this._relationGraphView?.value?.getInstance().dataUpdated();
        this._relationGraphView?.value?.getInstance().refresh();
    }
    saveRelationGraphData() {
        let graphJsonData = this._relationGraphView?.value?.getInstance().getGraphJsonData();
        if (!(graphJsonData && "lines" in graphJsonData && "nodes" in graphJsonData && "rootId" in graphJsonData)) {
            console.log("saveRelationGraph => invalid graphJsonData", graphJsonData);
            return;
        }
        store.dispatch("asyncUpdateGraphData", graphJsonData).then(r => {
            this.localStorage.setItem(this.graphDataStorageKey, JSON.stringify(graphJsonData));
        });
    }
    setRelationGraphView(relationGraph) {
        this._relationGraphView = relationGraph;
    }
    exportRelationGraphData() {
        const data = JSON.stringify(this.getRelationGraphData()); // 要导出的数据
        const blob = new Blob([data], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "data.g7y"; // 下载的文件名
        link.click();
        URL.revokeObjectURL(url);
    }
    importRelationGraphData() {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.addEventListener("change", () => {
            const file = fileInput.files?.[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    const fileContent = reader.result;
                    try {
                        const jsonData = JSON.parse(fileContent);
                    }
                    catch (error) {
                        ElNotification({ title: '错误', message: '非法的文件', type: 'error', });
                        return;
                    }
                    this.localStorage.setItem(this.graphDataStorageKey, fileContent);
                    this.createNewRelationGraphFromStorage();
                };
                reader.readAsText(file);
            }
        });
        fileInput.click();
    }
}
export const relationGraphDelegate = new RelationGraphDelegate();
