import { defineComponent } from "vue";
export default defineComponent({
    name: "GraphNodeInfo",
    components: {},
    props: {
        node: {
            type: Object,
            required: true
        }
    },
    setup() {
        return {};
    }
});
