export const createDefaultRelationGraphData = () => {
    return {
        rootId: "a",
        nodes: [
            {
                id: "a",
                text: "A",
                nodeShape: 0,
                borderColor: "#FFFF00",
                data: { avatar: "/img/icon.png" }
            }
        ],
        lines: []
    };
};
