import { defineComponent, computed } from "vue";
export default defineComponent({
    name: 'DefaultAvatarPicker',
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        avatars: {
            type: Array,
            default: () => []
        }
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const importAll = (r) => r.keys().forEach((key) => props.avatars?.push(r(key)));
        importAll(require.context('@/assets/avatars', true, /\.(png|jpe?g|gif|svg|webp)$/));
        const avatarGroups = computed(() => {
            const groups = [];
            const groupSize = 6;
            for (let i = 0; i < props.avatars.length; i += groupSize) {
                groups.push(props.avatars.slice(i, i + groupSize));
            }
            return groups;
        });
        function selectAvatar(avatar) {
            emit('update:modelValue', avatar);
        }
        return {
            selectAvatar,
            avatarGroups
        };
    }
});
