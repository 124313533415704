import { defineComponent } from "vue";
export default defineComponent({
    name: 'ColorPicker',
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        colors: {
            type: Array,
            default: () => []
        }
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        function selectColor(color) {
            emit('update:modelValue', color);
        }
        return {
            selectColor
        };
    }
});
